import React, { FC, memo, useCallback } from 'react';
import { getActions, withGlobal } from '../../../global';

import type { ApiChatFolder, ApiSticker } from '../../../api/types';
import {
  LeftColumnContent,
  MiddleColumnContent,
  SettingsScreens,
} from '../../../types';
import type { FolderEditDispatch } from '../../../hooks/reducers/useFoldersReducer';

import {
  selectAnimatedEmoji,
  selectChatFolder,
} from '../../../global/selectors';

import useAppLayout from '../../../hooks/useAppLayout';
import Button from '../../ui/Button';
import styles from './EmptyFolder.module.scss';
import { useTranslation } from 'react-i18next';
import AnimatedIcon from '../../common/AnimatedIcon';
import { LOCAL_TGS_URLS } from '../../common/helpers/animatedAssets';
import { historyPushState } from '../../../util/routing';
import useLastCallback from '../../../hooks/useLastCallback';
import useHistoryBack from '../../../hooks/useHistoryBack';

type OwnProps = {
  folderId?: number;
  folderType: 'all' | 'archived' | 'folder';
  foldersDispatch: FolderEditDispatch;
};

type StateProps = {
  chatFolder?: ApiChatFolder;
  animatedEmoji?: ApiSticker;
};

const ICON_SIZE = 240;

const EmptyFolder: FC<OwnProps & StateProps> = ({
  chatFolder,
  animatedEmoji,
  foldersDispatch,
}) => {
  const { setLeftScreen, setMiddleScreen, setSettingsScreen } = getActions();
  const { t, i18n } = useTranslation();
  const isRtl = i18n.dir(i18n.language) === 'rtl';
  const { isMobile } = useAppLayout();

  const { toggleScreens } = useHistoryBack({
    isActive: false,
  });

  const handleEditFolder = useCallback(() => {
    foldersDispatch({ type: 'editFolder', payload: chatFolder });
    setSettingsScreen({
      screen: SettingsScreens.FoldersEditFolderFromChatList,
    });
  }, [chatFolder, foldersDispatch, setSettingsScreen]);

  const handleClickInvite = useCallback(() => {
    setLeftScreen({ screen: LeftColumnContent.Settings });
    setMiddleScreen({ screen: MiddleColumnContent.Settings });
    setSettingsScreen({ screen: SettingsScreens.InvitationLink });
  }, []);

  const handleClickExplore = useLastCallback(() => {
    toggleScreens({
      leftScreen: LeftColumnContent.Feed,
      middleScreen: MiddleColumnContent.Feed,
      pageTitle: 'Feed',
      hash: 'feed',
    });
  });

  const handleClickAi = useLastCallback(() => {
    setLeftScreen({ screen: LeftColumnContent.Settings });
    setMiddleScreen({ screen: MiddleColumnContent.Settings });
    setSettingsScreen({ screen: SettingsScreens.aiSpace });
  });

  return (
    <div className={styles.root}>
      <div className={styles.centered}>
        <AnimatedIcon tgsUrl={LOCAL_TGS_URLS.Welcome} size={ICON_SIZE} />
        <p className={styles.description} dir='auto'>
          {chatFolder
            ? t('ChatList.EmptyChatListFilterText')
            : t('ChatList.EmptyChat')}
        </p>
      </div>

      <div className='button-go-explore'>
        <Button fullWidth onClick={handleClickExplore}>
          {t('ExploreChannels')}
        </Button>
      </div>

      {chatFolder && (
        <Button
          ripple={!isMobile}
          fluid
          pill
          onClick={handleEditFolder}
          size='smaller'
          isRtl={isRtl}
        >
          <i className='icon-settings' />
          <div className={styles.buttonText}>
            {t('ChatList.EmptyChatListEditFilter')}
          </div>
        </Button>
      )}
    </div>
  );
};

export default memo(
  withGlobal<OwnProps>((global, { folderId, folderType }): StateProps => {
    const chatFolder =
      folderId && folderType === 'folder'
        ? selectChatFolder(global, folderId)
        : undefined;

    return {
      chatFolder,
      animatedEmoji: selectAnimatedEmoji(global, '📂'),
    };
  })(EmptyFolder)
);
