import React, { AriaRole, CSSProperties } from 'react';

import type { IconName } from '../../../types/icons';
import classNames from 'classnames';

type OwnProps = {
  name: IconName;
  className?: string;
  style?: CSSProperties;
  role?: AriaRole;
  ariaLabel?: string;
  onClick?: (e: React.MouseEvent<HTMLDivElement>) => void;
};

const Icon = ({
  name,
  className,
  style,
  role,
  ariaLabel,
  onClick,
}: OwnProps) => {
  return (
    <i
      className={classNames(`icon icon-${name}`, className)}
      style={style}
      aria-hidden={!ariaLabel}
      aria-label={ariaLabel}
      role={role}
      onClick={onClick}
    />
  );
};

export default Icon;
