import React, {
  FC,
  ReactNode,
  RefObject,
  useRef,
  useCallback,
  CSSProperties,
} from 'react';

import { IS_TOUCH_ENV, MouseButton } from '../../util/windowEnvironment';
import { fastRaf } from '../../util/schedulers';

import useContextMenuHandlers from '../../hooks/useContextMenuHandlers';
import useContextMenuPosition from '../../hooks/useContextMenuPosition';
import useFlag from '../../hooks/useFlag';

import RippleEffect from './RippleEffect';
import Menu from './Menu';
import MenuItem from './MenuItem';
import MenuSeparator from './MenuSeparator';
import Button from './Button';

import './ListItem.scss';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import IconSvg from './IconSvg';
import useLastCallback from '../../hooks/useLastCallback';

type MenuItemContextActionItem = {
  title: string;
  icon: string;
  destructive?: boolean;
  handler?: () => void;
};

type MenuItemContextActionSeparator = {
  isSeparator: true;
  key?: string;
};

export type MenuItemContextAction =
  | MenuItemContextActionItem
  | MenuItemContextActionSeparator;

interface OwnProps {
  elRef?: RefObject<HTMLDivElement>;
  buttonRef?: RefObject<HTMLDivElement | HTMLAnchorElement>;
  icon?: string;
  leftElement?: ReactNode;
  secondaryIcon?: string;
  rightElement?: ReactNode;
  buttonClassName?: string;
  className?: string;
  style?: CSSProperties | undefined;
  children: React.ReactNode;
  disabled?: boolean;
  allowDisabledClick?: boolean;
  ripple?: boolean;
  narrow?: boolean;
  inactive?: boolean;
  focus?: boolean;
  destructive?: boolean;
  multiline?: boolean;
  isStatic?: boolean;
  contextActions?: MenuItemContextAction[];
  withPortalForMenu?: boolean;
  href?: string;
  onMouseDown?: (e: React.MouseEvent<HTMLDivElement>) => void;
  onClick?: (e: React.MouseEvent<HTMLElement>, arg?: any) => void;
  clickArg?: any;
  onContextMenu?: (e: React.MouseEvent<HTMLElement>) => void;
  onSecondaryIconClick?: (e: React.MouseEvent<HTMLButtonElement>) => void;
  onDragEnter?: (e: React.DragEvent<HTMLDivElement>) => void;
}
const ListItem: FC<OwnProps> = ({
  elRef,
  buttonRef,
  icon,
  leftElement,
  buttonClassName,
  secondaryIcon,
  rightElement,
  className,
  style,
  children,
  disabled,
  allowDisabledClick,
  ripple,
  narrow,
  inactive,
  focus,
  destructive,
  multiline,
  isStatic,
  contextActions,
  withPortalForMenu,
  href,
  onMouseDown,
  onClick,
  clickArg,
  onSecondaryIconClick,
  onDragEnter,
  onContextMenu,
}) => {
  let containerRef = useRef<HTMLDivElement>(null);
  if (elRef) {
    containerRef = elRef;
  }

  const [isTouched, markIsTouched, unmarkIsTouched] = useFlag();

  const {
    isContextMenuOpen,
    contextMenuPosition,
    handleBeforeContextMenu,
    handleContextMenu,
    handleContextMenuClose,
    handleContextMenuHide,
  } = useContextMenuHandlers(containerRef, !contextActions);

  const getTriggerElement = useCallback(() => containerRef.current, []);
  const getRootElement = useCallback(
    () => containerRef.current!.closest('.custom-scroll'),
    []
  );

  const getMenuElement = useCallback(
    () =>
      (withPortalForMenu
        ? document.querySelector('#portals')
        : containerRef.current)!.querySelector(
        '.ListItem-context-menu .bubble'
      ),
    [withPortalForMenu]
  );

  const getLayout = useCallback(
    () => ({ withPortal: withPortalForMenu }),
    [withPortalForMenu]
  );

  const {
    positionX,
    positionY,
    transformOriginX,
    transformOriginY,
    style: menuStyle,
  } = useContextMenuPosition(
    contextMenuPosition,
    getTriggerElement,
    getRootElement,
    getMenuElement,
    getLayout
  );

  const handleClickEvent = useCallback(
    (e: React.MouseEvent<HTMLElement, MouseEvent>) => {
      const hasModifierKey = e.ctrlKey || e.metaKey || e.shiftKey;
      if (!hasModifierKey && e.button === MouseButton.Main) {
        e.preventDefault();
      }
    },
    []
  );

  const handleClick = useCallback(
    (e: React.MouseEvent<HTMLElement, MouseEvent>) => {
      if ((disabled && !allowDisabledClick) || !onClick) {
        return;
      }

      if (href) {
        const hasModifierKey = e.ctrlKey || e.metaKey || e.shiftKey;

        if (
          (hasModifierKey && e.button === MouseButton.Main) ||
          e.button === MouseButton.Auxiliary
        ) {
          return;
        }
        e.preventDefault();
      }

      onClick(e, clickArg);

      if (IS_TOUCH_ENV && !ripple) {
        markIsTouched();
        fastRaf(unmarkIsTouched);
      }
    },
    [
      allowDisabledClick,
      clickArg,
      disabled,
      markIsTouched,
      onClick,
      ripple,
      unmarkIsTouched,
      href,
    ]
  );

  // const {
  //   handleClick: handleSecondaryIconClick,
  //   handleMouseDown: handleSecondaryIconMouseDown,
  // } = useFastClick((e: React.MouseEvent<HTMLButtonElement>) => {
  //
  //   if (
  //     (disabled && !allowDisabledClick) ||
  //     e.button !== 0 ||
  //     (!onSecondaryIconClick && !contextActions)
  //   )
  //     return;

  //   e.stopPropagation();

  //   if (onSecondaryIconClick) {
  //     onSecondaryIconClick(e);
  //   } else {
  //     handleContextMenu(e);
  //   }
  // });

  const handleSecondaryIconClick = (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    if (
      (disabled && !allowDisabledClick) ||
      e.button !== 0 ||
      (!onSecondaryIconClick && !contextActions)
    )
      return;
    e.stopPropagation();
    if (onSecondaryIconClick) {
      onSecondaryIconClick(e);
    } else {
      handleContextMenu(e);
    }
  };

  const handleMouseDown = useLastCallback(
    (e: React.MouseEvent<HTMLElement, MouseEvent>) => {
      if (inactive || IS_TOUCH_ENV || secondaryIcon) {
        return;
      }
      if (contextActions && (e.button === MouseButton.Secondary || !onClick)) {
        handleBeforeContextMenu(e);
      }
      if (e.button === MouseButton.Main) {
        if (!onClick) {
          handleContextMenu(e);
        } else {
          handleClick(e);
        }
      }
    }
  );

  const { t, i18n } = useTranslation();
  const isRtl = i18n.dir(i18n.language) === 'rtl';
  const fullClassName = classNames('ListItem', className, {
    'no-selection': !isStatic,
    'has-ripple': ripple,
    narrow,
    disabled,
    'click-allowed': allowDisabledClick,
    inactive,
    'has-menu-open': contextMenuPosition,
    focus,
    destructive,
    multiline,
    'is-static': isStatic,
    'has-action': secondaryIcon,
  });

  const ButtonElementTag = href ? 'a' : 'div';

  return (
    <div
      ref={containerRef}
      className={fullClassName}
      dir={isRtl ? 'rtl' : undefined}
      style={style}
      onMouseDown={onMouseDown}
      onDragEnter={onDragEnter}
    >
      <ButtonElementTag
        className={classNames('ListItem-button', buttonClassName, {
          active: isTouched,
        })}
        role={!isStatic ? 'button' : undefined}
        href={href}
        ref={buttonRef as any} //TS requires specific types for refs
        tabIndex={!isStatic ? 0 : undefined}
        onClick={
          Boolean(!inactive && (secondaryIcon || IS_TOUCH_ENV))
            ? handleClick
            : handleClickEvent
        }
        onMouseDown={handleMouseDown}
        onContextMenu={
          onContextMenu ||
          (!inactive && contextActions ? handleContextMenu : undefined)
        }
      >
        {leftElement}
        {icon && <i className={`icon-${icon}`} />}
        {multiline && <div className='multiline-item'>{children}</div>}
        {!multiline && children}
        {!disabled && !inactive && ripple && <RippleEffect />}
        {secondaryIcon && (
          <Button
            className='secondary-icon'
            round
            color='translucent'
            size='smaller'
            onClick={handleSecondaryIconClick}
          >
            <i className='icon-svg'>
              <IconSvg name={secondaryIcon} />
            </i>
          </Button>
        )}
        {rightElement}
      </ButtonElementTag>

      {contextActions && contextMenuPosition !== undefined && (
        <Menu
          isOpen={isContextMenuOpen}
          transformOriginX={transformOriginX}
          transformOriginY={transformOriginY}
          positionX={positionX}
          positionY={positionY}
          style={menuStyle}
          className='ListItem-context-menu'
          autoClose
          onClose={handleContextMenuClose}
          onCloseAnimationEnd={handleContextMenuHide}
          withPortal={withPortalForMenu}
          backdropExcludedSelector='.secondary-icon'
        >
          {contextActions.map((action) =>
            'isSeparator' in action ? (
              <MenuSeparator key={action.key || 'separator'} />
            ) : (
              <MenuItem
                key={action.title}
                customIcon={<IconSvg name={action.icon} />}
                destructive={action.destructive}
                disabled={!action.handler}
                onClick={action.handler}
              >
                {action.title}
              </MenuItem>
            )
          )}
        </Menu>
      )}
    </div>
  );
};

export default ListItem;
