import React, { FC, memo, useCallback, useMemo, useState } from 'react';
import IconSvg from './IconSvg';
import ListItem from './ListItem';
import { ApiChat, ApiExportedInvite } from '../../api/types';
import { APP_LINK_PREFIX } from '../../config';
import { useTranslation } from 'react-i18next';
import useLastCallback from '../../hooks/useLastCallback';
import { copyTextToClipboard } from '../../util/clipboard';
import { getActions, withGlobal } from '../../global';
import { selectChat } from '../../global/selectors';
import useHistoryBack from '../../hooks/useHistoryBack';
import { SettingsScreens } from '../../types';
import ConfirmDialog from './ConfirmDialog';
import useFlag from '../../hooks/useFlag';
import { formatCountdown } from '../../util/dateFormat';
import { getServerTime } from '../../util/serverTime';
import classNames from 'classnames';
import useInterval from '../../hooks/useInterval';
import Time from '../../util/Time';

type OwnProps = {
  invite: ApiExportedInvite;
};

type StateProps = {
  chat?: ApiChat;
};

const BULLET = '\u2022';

const InviteLinkItem: FC<OwnProps & StateProps> = ({ invite, chat }) => {
  const {
    showNotification,
    setEditingExportedInvite,
    editExportedChatInvite,
    deleteExportedChatInvite,
  } = getActions();
  const { t } = useTranslation();

  const {
    isPermanent,
    isRequestNeeded,
    isRevoked,
    link,
    title,
    expireDate,
    date,
    usageLimit,
    usage,
    requested,
  } = invite;

  const { toggleScreens } = useHistoryBack({ isActive: false });

  const [isDeleteDialogOpen, openDeleteDialog, closeDeleteDialog] = useFlag();
  const [isRevokeDialogOpen, openRevokeDialog, closeRevokeDialog] = useFlag();

  const [diff, setDiff] = useState(
    expireDate ? Time.getSecondsFromExpiry(expireDate * 1000, true) : undefined
  );

  useInterval(() => {
    if (expireDate && diff && diff > 0) {
      setDiff(Time.getSecondsFromExpiry(expireDate * 1000, true));
    }
  }, 1000);

  const copyLink = useLastCallback((link: string) => {
    copyTextToClipboard(link);
    showNotification({
      message: t('Link.Copied'),
    });
  });

  const editInvite = () => {
    if (chat) {
      setEditingExportedInvite({ chatId: chat.id, invite });
      toggleScreens({ settingScreen: SettingsScreens.CreateLink });
    }
  };

  const handleRevoke = useLastCallback(() => {
    editExportedChatInvite({
      chatId: chat?.id!,
      link,
      title,
      isRequestNeeded,
      expireDate,
      usageLimit,
      isRevoked: true,
    });
    closeRevokeDialog();
  });

  const handleDelete = useLastCallback(() => {
    deleteExportedChatInvite({ chatId: chat?.id!, link: invite.link });
  });

  const prepareContextActions = () => {
    const actions = [
      {
        title: t('Link.Copy'),
        icon: 'copy',
        handler: () => copyLink(link),
      },
    ];

    if (!isPermanent && !isRevoked) {
      actions.push({
        title: t('Edit'),
        icon: 'edit',
        handler: editInvite,
      });
    }

    if (isRevoked) {
      actions.push({
        title: t('Link.Delete'),
        icon: 'delete',
        handler: openDeleteDialog,
      });
    } else {
      actions.push({
        title: t('Link.Revoke'),
        icon: 'delete',
        handler: openRevokeDialog,
      });
    }

    return actions;
  };

  const prepareUsageText = useCallback(() => {
    let text = '';
    if (!isRevoked && usageLimit && usage && usage < usageLimit) {
      text = t('Link.CanJoin', { count: usageLimit - usage });
    } else if (usage) {
      text = t('Link.PeopleJoined', { count: usage });
    } else {
      text = t('Link.NoOneJoined');
    }

    if (isRevoked) {
      text += ` ${BULLET} ${t('Revoked')}`;
      return text;
    }

    if (requested) {
      text += ` ${BULLET} ${t('Link.JoinRequests', { requested })}`;
    }

    if (usageLimit !== undefined && usage === usageLimit) {
      text += ` ${BULLET} ${t('LimitReached')}`;
    } else if (expireDate) {
      text += ` ${BULLET} `;
      if (diff! > 0) {
        text += t('Link.ExpiresIn', {
          link: String(formatCountdown(t, diff! * 1000)),
        });
      } else {
        text += t('Link.Expired');
      }
    } else if (isPermanent) {
      text += ` ${BULLET} ${t('Link.Primary')}`;
    }

    return text;
  }, [expireDate, diff]);

  const getIcon = useCallback(() => {
    let className = 'icon-svg';
    let nameLink = 'link';
    let wick = false;
    let dashoffset = 0;

    if (expireDate) {
      if (diff === 0) {
        className = className.concat(' ', 'error');
      } else {
        className = className.concat(' ', 'expire');
        wick = true;

        dashoffset = -(
          (116 *
            ((Time.getSecondsFromPrevTime(date * 1000, true) * 100) /
              (expireDate! - date))) /
          100
        );
      }
    }

    return (
      <i className={className}>
        <IconSvg name={nameLink} />
        {wick && (
          <svg
            className='wick'
            xmlns='http://www.w3.org/2000/svg'
            width='40'
            height='40'
            fill='none'
            viewBox='0 0 40 40'
          >
            <circle
              cx='20'
              cy='20'
              r='18.5'
              stroke='#44BE2E'
              style={{ strokeDasharray: 116, strokeDashoffset: dashoffset }}
            />
            {/* <circle cx='20' cy='1.5' r='1.5' fill='#44BE2E' /> */}
          </svg>
        )}
      </i>
    );
  }, [expireDate, diff]);

  return (
    <>
      <ListItem
        ripple
        leftElement={getIcon()}
        secondaryIcon='filled'
        className='invite-link chat-item-clickable smaller underline'
        contextActions={prepareContextActions()}
      >
        <div className='info'>
          <div className='link-title'>
            {invite.title || invite.link.replace(`${APP_LINK_PREFIX}+`, '')}
          </div>
          <div className='subtitle'>{prepareUsageText()}</div>
        </div>
      </ListItem>
      <ConfirmDialog
        isOpen={isRevokeDialogOpen}
        onClose={closeRevokeDialog}
        title={String(t('Link.Reset'))}
        text={String(t('Link.ResetAlert'))}
        confirmIsDestructive
        confirmLabel={String(t('Link.Remove'))}
        confirmHandler={handleRevoke}
      />
      <ConfirmDialog
        isOpen={isDeleteDialogOpen}
        onClose={closeDeleteDialog}
        title={String(t('Link.Remove'))}
        text={String(t('Link.RevokeAlert'))}
        confirmIsDestructive
        confirmLabel={String(t('Delete'))}
        confirmHandler={handleDelete}
      />
    </>
  );
};

export default memo(
  withGlobal((global): StateProps => {
    const { currentUserId } = global;

    const chat = currentUserId ? selectChat(global, currentUserId) : undefined;

    return {
      chat,
    };
  })(InviteLinkItem)
);
