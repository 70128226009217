export const themList = [
  '#0A49A5',
  '#9B51E0',
  '#5179E0',
  '#F2C94C',
  '#44BE2E',
  '#2D9CDB',
  '#AE9DF3',
  '#FF9900',
  '#EF4062',
  '#AE9DF3',
  '#0A49A5',
  '#9B51E0',
  '#5179E0',
  '#F2C94C',
  '#44BE2E',
  '#2D9CDB',
  '#AE9DF3',
  '#FF9900',
  '#EF4062',
  '#AE9DF3',
]