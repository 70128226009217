import React, {
  FC,
  memo,
  useCallback,
  useEffect,
  useMemo,
  useRef,
} from 'react';
import QRCodeStyling from '../../../lib/qr-code-styling';
import {
  ApiChat,
  ApiExportedInvite,
  ApiMediaFormat,
  ApiUser,
} from '../../../api/types';

import './SettingsInvitationLink.scss';
import { getChatAvatarHash, isUserId } from '../../../global/helpers';
import { getActions, withGlobal } from '../../../global';
import {
  selectChat,
  selectTabState,
  selectUser,
} from '../../../global/selectors';
import IconSvg from '../../ui/IconSvg';
import { useTranslation } from 'react-i18next';
import Button from '../../ui/Button';
import { SettingsScreens } from '../../../types';
import Transition from '../../ui/Transition';
import SettingsCreateInviteLink from './SettingsCreateInviteLink';

import Logo from '../../../assets/logo-qr.png';
import InviteLink from '../../common/InviteLink';
import { APP_LINK_PREFIX } from '../../../config';
import useFlag from '../../../hooks/useFlag';
import ConfirmDialog from '../../ui/ConfirmDialog';
import InviteLinkItem from '../../ui/InviteLinkItem';
import useMedia from '../../../hooks/useMedia';

type StateProps = {
  chat?: ApiChat;
  user?: ApiUser;
  currentUserId?: string;
  exportedInvites?: ApiExportedInvite[];
  revokedExportedInvites?: ApiExportedInvite[];
  lastSyncTime?: number;
};

type OwnProps = {
  shownScreen: SettingsScreens;
  onScreenSelect: (screen: SettingsScreens) => void;
  isActive?: boolean;
  onReset: () => void;
};

const QR_SIZE = 263;

const qrCode = new QRCodeStyling({
  width: QR_SIZE,
  height: QR_SIZE + 60,
  margin: 30,
  type: 'svg',
  dotsOptions: {
    type: 'rounded',
    color: '#fff',
  },
  backgroundOptions: {
    color: '#6CA5F9',
  },
  cornersSquareOptions: {
    type: 'extra-rounded',
  },
  image: Logo,
  imageOptions: {
    imageSize: 0.3,
    margin: 3,
  },

  qrOptions: {
    errorCorrectionLevel: 'Q',
  },
});

const BULLET = '\u2022';

function inviteComparator(i1: ApiExportedInvite, i2: ApiExportedInvite) {
  const { isPermanent: i1IsPermanent, usage: i1Usage = 0, date: i1Date } = i1;
  const { isPermanent: i2IsPermanent, usage: i2Usage = 0, date: i2Date } = i2;
  if (i1IsPermanent || i2IsPermanent)
    return Number(i1IsPermanent) - Number(i2IsPermanent);
  if (i1Usage || i2Usage) return i2Usage - i1Usage;
  return i2Date - i1Date;
}

const SettingsInvitationLink: FC<StateProps & OwnProps> = ({
  chat,
  user,
  shownScreen,
  onScreenSelect,
  isActive,
  onReset,
  exportedInvites,
  revokedExportedInvites,
  lastSyncTime,
}) => {
  const {
    loadExportedChatInvites,

    deleteRevokedExportedChatInvites,
  } = getActions();
  const qrCodeRef = useRef<HTMLDivElement>(null);
  const { t } = useTranslation();
  const { usernames, isPublic } = user || {};

  const imageHash = getChatAvatarHash(user!, 'normal');

  const imgBlobUrl = useMedia(
    imageHash,
    false,
    ApiMediaFormat.BlobUrl,
    lastSyncTime
  );

  const [
    isDeleteRevokeAllDialogOpen,
    openDeleteRevokeAllDialog,
    closeDeleteRevokeAllDialog,
  ] = useFlag();

  const temporalInvites = useMemo(() => {
    const invites = usernames
      ? exportedInvites
      : exportedInvites?.filter(({ isPermanent }) => !isPermanent);
    return invites?.sort(inviteComparator);
  }, [chat?.usernames, exportedInvites]);

  const activeUsernames = useMemo(() => {
    const result = usernames?.filter((u) => u.isActive);

    return result?.length ? result : undefined;
  }, [usernames]);

  const invite = exportedInvites?.find(
    (el) => !el.usageLimit && !el.expireDate
  )?.link;

  const link = useMemo(() => {
    return !isPublic
      ? invite
      : `${APP_LINK_PREFIX}${activeUsernames?.[0].username}`;
  }, [activeUsernames, invite]);

  const handleCreateNewLink = useCallback(() => {
    onScreenSelect(SettingsScreens.CreateLink);
  }, []);

  const handleDeleteAllRevoked = useCallback(() => {
    deleteRevokedExportedChatInvites({ chatId: chat?.id! });
    closeDeleteRevokeAllDialog();
  }, [chat, closeDeleteRevokeAllDialog, deleteRevokedExportedChatInvites]);

  useEffect(() => {
    qrCode.append(qrCodeRef.current!);
  }, []);

  useEffect(() => {
    qrCode.update({
      data: link,
      avatar: imgBlobUrl || '',
      text: `@${activeUsernames?.[0].username}`,
    });
  }, [link, imgBlobUrl, activeUsernames]);

  useEffect(() => {
    if (lastSyncTime && chat) {
      loadExportedChatInvites({ chatId: chat.id, isRevoked: true });
    }
  }, [chat, loadExportedChatInvites, lastSyncTime]);

  function renderCurrentSectionContent() {
    switch (shownScreen) {
      case SettingsScreens.CreateLink:
        return (
          <SettingsCreateInviteLink
            isActive={isActive}
            onReset={onReset}
            onScreenSelect={onScreenSelect}
          />
        );
      default:
        return (
          <div className='settings-invitation'>
            <div className='qr-inner'>
              <div className='qr-container' ref={qrCodeRef} />

              {/* <div className='qr-wrapper'>
                <Avatar peer={user || chat} size='jumbo' />
                <div className='qr-container' ref={qrCodeRef} />
                <h3>{`@${activeUsernames?.[0].username}`}</h3>
              </div> */}

              <div className='title'>{t('Link.Invite')}</div>
              <InviteLink
                inviteLink={link || ''}
                onRevoke={!chat?.usernames ? () => true : undefined}
                qrCodeStyling={qrCode}
              />
              <Button
                isLink
                className='mb-1 mt-3'
                onClick={handleCreateNewLink}
              >
                <i className='icon-add mr-3' /> {t('Link.CreateNew')}
              </Button>

              <div className='links-list'>
                {temporalInvites?.map((invite) => (
                  <InviteLinkItem invite={invite} />
                ))}
              </div>
              {revokedExportedInvites &&
                Boolean(revokedExportedInvites.length) && (
                  <>
                    <div className='title'>{t('Link.Revoked')}</div>
                    <div className='links-list'>
                      {revokedExportedInvites?.map((invite) => (
                        <InviteLinkItem invite={invite} />
                      ))}
                    </div>
                    <Button
                      isLink
                      color='danger'
                      className='mb-1'
                      onClick={openDeleteRevokeAllDialog}
                    >
                      <i className='icon-svg mr-3'>
                        <IconSvg name='delete' />
                      </i>
                      {t('Link.RemoveAll')}
                    </Button>
                  </>
                )}
            </div>
          </div>
        );
    }
  }

  return (
    <div className='settings-container'>
      <Transition name='fade' activeKey={shownScreen}>
        {renderCurrentSectionContent()}
      </Transition>

      <ConfirmDialog
        isOpen={isDeleteRevokeAllDialogOpen}
        onClose={closeDeleteRevokeAllDialog}
        title={String(t('Link.DeleteAllRevoked'))}
        text={String(t('Link.DeleteAllRevokedLinkHelp'))}
        confirmIsDestructive
        confirmLabel={String(t('DeleteAll'))}
        confirmHandler={handleDeleteAllRevoked}
      />
    </div>
  );
};

export default memo(
  withGlobal((global): StateProps => {
    const { currentUserId } = global;
    const { management } = selectTabState(global);

    const user = isUserId(currentUserId!)
      ? selectUser(global, currentUserId!)
      : undefined;
    const chat = currentUserId ? selectChat(global, currentUserId) : undefined;
    const { invites, revokedInvites } = management.byChatId[chat?.id!] || {};

    return {
      currentUserId,
      chat,
      user,
      lastSyncTime: global.lastSyncTime,
      exportedInvites: invites,
      revokedExportedInvites: revokedInvites,
    };
  })(SettingsInvitationLink)
);
